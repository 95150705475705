import React from 'react';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`,
  smart: `sm:py-5 sm:px-16 sm:text-lg text-md py-2 px-6`,
  organized: `lg:py-3 lg:px-8 py-2 px-4`,
  none: ''
};

const Button = ({ children, className = '', disabled = false, size, type = 'button', onClick = () => {} }) => {
  return (
    <button
      type={type}
      className={`
        ${ size ? sizes[size] || sizes.default : ''}
        hover:bg-primary-darker
        rounded
        ${className}
    `}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
